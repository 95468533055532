<template>
	<v-app>
		<v-main>
			<v-row class="fill-height" align="start" justify="center">
				<!-- Left Side Image Section -->
				<v-col cols="12" md="6" class="d-none d-md-block pa-0">
					<v-img
						src="@/assets/img/common/login.jpg"
						alt="Forgot password page illustration"
					>
					</v-img>
				</v-col>

				<!-- Right Side Form Section -->
				<v-col cols="12" md="6" class="mt-8 px-6 px-sm-16">
					<div class="mx-0 mx-sm-8 mx-md-n6 mx-lg-16">
						<!-- Header Content -->
						<header>
							<a href="https://www.unosearch.net">
								<v-img
									src="@/assets/img/logo/brand-icon.svg"
									max-height="60"
									max-width="60"
									class="mb-6"
									to="/"
									contain
								></v-img>
							</a>

							<h1>Reset your password</h1>
							<p
								v-if="resetPasswordForm.email"
								class="grey--text text--darken-1"
							>
								Enter a new password for
								<strong>
									{{ resetPasswordForm.email }}
								</strong>
							</p>
						</header>

						<!-- Actual Form -->
						<v-form class="mt-8" @submit.prevent="resetPassword">
							<!-- Alert box to display Success Message -->
							<v-alert
								:value="showSuccessAlert"
								type="success"
								dismissible
								prominent
								@click="showSuccessAlert = false"
								transition="fade-transition"
								class="mb-10"
							>
								Awesome! your password has been reset
								successfully. Click here to
								<router-link to="/login" class="link-text">
									Login.
								</router-link>
							</v-alert>

							<!-- Alert box to display Server Errors -->
							<v-alert
								:value="showServerErrorAlert"
								type="error"
								dismissible
								transition="fade-transition"
								@click="showServerErrorAlert = false"
								class="mb-10"
							>
								{{ serverErrorMessage }}
							</v-alert>

							<!-- Password Field -->
							<v-text-field
								v-model="resetPasswordForm.password"
								outlined
								placeholder="New password"
								required
								:append-icon="
									showPassword
										? 'mdi mdi-eye-off'
										: 'mdi mdi-eye'
								"
								@click:append="showPassword = !showPassword"
								:type="showPassword ? 'text' : 'password'"
								:error-messages="passwordErrors"
								@blur="$v.resetPasswordForm.password.$touch()"
							></v-text-field>

							<!-- Confirm Password Field -->
							<v-text-field
								v-model="resetPasswordForm.confirmPassword"
								outlined
								placeholder="Confirm new password"
								required
								:append-icon="
									showPassword
										? 'mdi mdi-eye-off'
										: 'mdi mdi-eye'
								"
								@click:append="showPassword = !showPassword"
								:type="showPassword ? 'text' : 'password'"
								:error-messages="confirmPasswordErrors"
								@blur="
									$v.resetPasswordForm.confirmPassword.$touch()
								"
							></v-text-field>

							<!-- Submit Button -->
							<v-btn
								type="submit"
								@click.prevent="resetPassword()"
								:loading="isLoading"
								x-large
								block
								color="primary"
								class="white--text mt-2"
							>
								Reset Password
							</v-btn>

							<!-- Link to Login Page -->
							<p
								v-if="showSuccessAlert"
								class="
									text-center
									mt-8
									grey--text
									text--darken-2
								"
							>
								<router-link to="/login" class="link-text">
									<v-icon color="primary">
										mdi-chevron-left
									</v-icon>
									Back to Login
								</router-link>
							</p>
						</v-form>
					</div>
				</v-col>
			</v-row>
		</v-main>
	</v-app>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required,
	minLength,
	maxLength,
	email,
	sameAs,
} from 'vuelidate/lib/validators'

export default {
	mixins: [validationMixin],
	validations: {
		resetPasswordForm: {
			email: {
				required,
				email,
				maxLength: maxLength(100),
			},
			password: {
				required,
				minLength: minLength(8),
				maxLength: maxLength(100),
			},
			confirmPassword: {
				required,
				sameAsPassword: sameAs('password'),
			},
		},
	},
	data() {
		return {
			resetPasswordForm: {
				email: '',
				password: '',
				confirmPassword: '',
				resetPasswordToken: '',
			},
			showPassword: false,
			showSuccessAlert: false,
			showServerErrorAlert: false,
			serverErrorMessage: '',
			isLoading: false,
		}
	},
	created() {
		this.resetPasswordForm.email = this.$route.query.email
		this.resetPasswordForm.resetPasswordToken = this.$route.query.token
	},
	computed: {
		passwordErrors() {
			const errors = []
			if (!this.$v.resetPasswordForm.password.$dirty) return errors
			!this.$v.resetPasswordForm.password.required &&
				errors.push('Please enter a new Password.')
			!this.$v.resetPasswordForm.password.minLength &&
				errors.push('Password must be atleast 8 characters long.')
			!this.$v.resetPasswordForm.password.maxLength &&
				errors.push('Password must be atmost 100 characters long.')
			return errors
		},
		confirmPasswordErrors() {
			const errors = []
			if (!this.$v.resetPasswordForm.confirmPassword.$dirty) return errors
			!this.$v.resetPasswordForm.confirmPassword.required &&
				errors.push('Please confirm your password by entering again.')
			!this.$v.resetPasswordForm.confirmPassword.sameAsPassword &&
				errors.push('Password do not match.')
			return errors
		},
	},
	methods: {
		resetPassword() {
			this.$v.resetPasswordForm.$touch()
			if (
				!this.$v.resetPasswordForm.$invalid ||
				!this.$v.resetPasswordForm.$error
			) {
				this.isLoading = true
				this.axios
					.post('/auth/reset-password', this.resetPasswordForm)
					.then((res) => {
						if (res.data.success) {
							this.isLoading = false
							this.showServerErrorAlert = false
							this.resetPasswordForm.password = ''
							this.resetPasswordForm.confirmPassword = ''
							this.$v.resetPasswordForm.$reset()
							this.showSuccessAlert = true
						}
					})
					.catch((err) => {
						this.isLoading = false
						this.showSuccessAlert = false
						this.resetPasswordForm.password = ''
						this.resetPasswordForm.confirmPassword = ''
						this.$v.resetPasswordForm.$reset()

						const { data } = err.response
						this.serverErrorMessage = !data.success
							? data.message
							: err
						this.showServerErrorAlert = true
						throw new Error(err)
					})
			}
		},
	},
}
</script>

<style scoped>
.v-image {
	height: 100vh !important;
}
.v-input {
	font-size: 1.1rem;
}
.link-text {
	text-decoration: none;
	color: --var(primary);
}
</style>
